import classNames from 'classnames'
import { ReactNode } from 'react'
import HexAnimation from '../../../hex-animation'
import Typography from '../../../typography'
import { header, headerAnimation, headerContent } from './header.module.scss'

interface HeaderProps {
  title: ReactNode
  subtitle?: ReactNode
  className?: string
}

const Header = ({ title, subtitle, className }: HeaderProps) => (
  <div className={classNames(header, className)}>
    <div className={headerContent}>
      <Typography variant="h2" component="h1">
        {title}
      </Typography>
      {subtitle && (
        <Typography
          gutterTop
          variant="body1"
          component="h2"
          color="textSecondary"
        >
          {subtitle}
        </Typography>
      )}
    </div>
    <HexAnimation
      className={headerAnimation}
      icons={[{ color: 'red' }, { color: 'green' }, { color: 'blue' }]}
    />
  </div>
)

export default Header
