import Button from '../button'
import Container from '../container'
import HexAnimation from '../hex-animation'
import Typography from '../typography'
import { callout, calloutContainer } from './callout.module.scss'

const Callout = () => (
  <div className={callout}>
    <Container className={calloutContainer}>
      <Typography variant="h2" color="inherit" align="center">
        Work with us
      </Typography>
      <Button variant="standard" link={{ to: '/contact', color: 'default' }}>
        Let&apos;s discuss your project
      </Button>
    </Container>
    <HexAnimation icons={3} />
  </div>
)

export default Callout
