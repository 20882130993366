import classNames from 'classnames'
import { chip } from './chip.module.scss'

interface ChipProps {
  label: string
  className?: string
}

const Chip = ({ label, className }: ChipProps) => (
  <span className={classNames(chip, className)}>{label}</span>
)

export default Chip
