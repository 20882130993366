import classNames from 'classnames'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { LearnMoreButton } from '../../../button'
import { CardContent } from '../../../card'
import Chip from '../../../chip'
import Link from '../../../link'
import Typography from '../../../typography'
import {
  work,
  workCategories,
  workContent,
  workContentFeatured,
  workFeatured,
  workImage,
  workImageFeatured,
} from './work.module.scss'

export interface WorkProps {
  name: string
  description: string
  image?: IGatsbyImageData
  link?: string
  categories?: string[]
  featured?: boolean
}

const WorkImage = ({
  image,
  link,
  name,
  featured,
}: Pick<WorkProps, 'image' | 'featured' | 'name' | 'link'>) => {
  if (!image) return null
  const gatsbyImage = (
    <GatsbyImage
      image={image}
      alt={name}
      className={classNames(workImage, {
        [workImageFeatured]: featured,
      })}
    />
  )
  if (link && featured) return <Link to={link}>{gatsbyImage}</Link>
  return gatsbyImage
}

const Work = ({
  name,
  description,
  image,
  categories,
  featured,
  link,
}: WorkProps) => (
  <div
    className={classNames(work, {
      [workFeatured]: featured,
    })}
  >
    <CardContent
      className={classNames(workContent, {
        [workContentFeatured]: featured,
      })}
    >
      <Typography component="h3" variant={featured ? 'h3' : 'h5'}>
        {name}
      </Typography>
      <Typography
        gutterTop
        variant={featured ? 'body1' : 'body2'}
        color="textSecondary"
      >
        {description}
      </Typography>
      <div className={workCategories}>
        {categories?.map(category => (
          <Chip key={category} label={category} />
        ))}
      </div>
      <LearnMoreButton link={featured ? { to: link } : undefined} />
    </CardContent>
    <WorkImage featured={featured} image={image} name={name} link={link} />
  </div>
)

const WorkWrapper = ({ link, featured, ...rest }: WorkProps) =>
  link && !featured ? (
    <Link to={link}>
      <Work featured={featured} link={link} {...rest} />
    </Link>
  ) : (
    <Work featured={featured} link={link} {...rest} />
  )

export default WorkWrapper
