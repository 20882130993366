const colors = {
  white: '#ffffff',
  black: '#000000',
  blue: '#0066ff',
  green: '#3aa76d',
  yellow: '#ffc043',
  purple: '#7356bf',
  red: '#d44333',
  orange: '#ed6e33',
}

export type Color = keyof typeof colors

export default colors
