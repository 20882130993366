import classNames from 'classnames'
import { ReactNode } from 'react'
import Element, { ElementProps } from '../../../element'
import Link, { LinkProps } from '../../../link'
import { cardContent } from './card-content.module.scss'

export interface CardProps extends ElementProps {
  className?: string
  link?: Omit<LinkProps, 'children'>
  children: ReactNode
}

const CardContent = ({ link, children, className, as }: CardProps) => {
  const classes = classNames(cardContent, className)
  if (link)
    return (
      <Link {...link} className={classes}>
        {children}
      </Link>
    )

  return (
    <Element as={as} className={classes}>
      {children}
    </Element>
  )
}

export default CardContent
