import classNames from 'classnames'
import colors, { Color } from '../../utils/colors'
import Icon from '../icon'
import {
  hexAnimation,
  hexAnimationSpinDown,
  hexAnimationSpinUp,
} from './hex-animation.module.scss'

const classes = [hexAnimationSpinUp, hexAnimationSpinDown]

type HexIcon = { color: Color; fade?: boolean }

interface HexAnimationProps {
  icons: number | HexIcon[]
  className?: string
}

const HexAnimation = ({ icons, className }: HexAnimationProps) => (
  <div className={classNames(hexAnimation, className)}>
    {(typeof icons === 'number'
      ? new Array<HexIcon>(icons).fill({ color: 'white', fade: true })
      : icons
    ).map((icon, index) => (
      <Icon
        key={index.toString()}
        name="hexagon"
        size={Math.floor(Math.random() * 24) + 18}
        className={classes[Math.floor(Math.random() * classes.length)]}
        style={{
          fillOpacity: icon.fade ? 0.5 : 1,
          fill: colors[icon.color],
          top: `-${Math.floor(Math.random() * 5) + 1}px`,
          left: `${Math.floor(Math.random() * 20) + 10}px`,
          order: Math.floor(Math.random() * 4) + 1,
          animationDuration: `${Math.floor(Math.random() * 24) + 10}s`,
        }}
      />
    ))}
  </div>
)

export default HexAnimation
