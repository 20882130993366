import PageWorks from '../../components/page-works'
import Seo from '../../components/seo'

const Work = () => (
  <>
    <Seo title="Work" />
    <PageWorks />
  </>
)

export default Work
