import useWorks from '../../hooks/work'
import Callout from '../callout'
import Card from '../card'
import Page from '../page'
import Typography from '../typography'
import Work from './components/work'
import { pageWorksItems } from './page-works.module.scss'

const PageWorks = () => {
  const works = useWorks()
  const featuredWork = works[0]
  const otherWorks = works.slice(1)

  return (
    <>
      <Page container>
        <Work
          featured
          name={featuredWork.name}
          description={featuredWork.description}
          image={featuredWork.image}
          categories={featuredWork.categories}
          link={featuredWork.link}
        />
        <section className={pageWorksItems}>
          <Typography variant="h2" align="center">
            More of our stellar work
          </Typography>
          <ul>
            {otherWorks?.map(work => (
              <Card key={work.id} as="li">
                <Work
                  name={work.name}
                  description={work.description}
                  image={work.image}
                  categories={work.categories}
                  link={work.link}
                />
              </Card>
            ))}
          </ul>
        </section>
      </Page>
      <Callout />
    </>
  )
}

export default PageWorks
