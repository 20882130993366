import classNames from 'classnames'
import { ReactNode } from 'react'
import Element, { ElementProps } from '../element'
import Link, { LinkProps } from '../link'
import { card } from './card.module.scss'

export interface CardProps extends ElementProps {
  className?: string
  link?: Omit<LinkProps, 'children'>
  children: ReactNode
}

const Card = ({ link, children, className, as, ...rest }: CardProps) => {
  const classes = classNames(card, className)
  if (link)
    return (
      <Link
        {...link}
        onMouseEnter={rest.onMouseEnter}
        onMouseLeave={rest.onMouseLeave}
        className={classes}
      >
        {children}
      </Link>
    )

  return (
    <Element {...rest} as={as} className={classes}>
      {children}
    </Element>
  )
}

export default Card
